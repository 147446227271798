import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { EnhancedTableHead } from '../../../components/TableComponent/TableWithSelection';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import {
  CREATE_ROLES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
} from '../../../router/PathConst';
import RoleMenu from './RoleMenu';
import ApiConstant from '../../../utils/apiConstant';
import { get, put } from '../../../utils/apiMethods';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import DeleteModal from '../../../modals/Common/DeleteModal';
import { deleteRoleById, deleteMessage } from '../../../utils/common';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'Roles',
    path: ROLES_PATH,
    active: true,
  },
];

const options = [
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Active', label: 'Active' },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

export default class Roles extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      page: 0,
      rowsPerPage: 10,
      UUID: [],
      roleList: [],
      roleRefList: [],
      loading: true,
      isDone: true,
      selectedRoles: [],
      isAllresource: false,
      remaining: 0,
    };
  }

  componentDidMount() {
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && 'sorting' in props) {
        const sort = JSON.parse(props.sorting);
        if ('Roles' in sort && sort.Roles.length > 0) {
          this.setState({ orderBy: sort.Roles[0], order: sort.Roles[1] });
        } else {
          this.setState({ orderBy: '' });
        }
      } else {
        this.setState({ orderBy: '' });
      }
    }

    this.getRoles();
  }

  // Get Roles
  getRoles = async () => {
    const response = await get({ url: ApiConstant.GET_ROLES });

    if (response) {
      const newArray = response
        .filter((data) => !data.name.startsWith('SHADOW'))
        .map((data) => ({
          ...data,
          status: data.status === 'active' ? 'Active' : 'Inactive',
        }))
        .sort((a, b) => {
          if (a.is_system_role && !b.is_system_role) {
            return -1;
          }
          if (!a.is_system_role && b.is_system_role) {
            return 1;
          }
          return 0;
        });

      this.setState({
        roleList: newArray,
        roleRefList: newArray,
        isDone: true,
        loading: false,
      });
    } else {
      this.setState({
        roleList: [],
        roleRefList: [],
        isDone: true,
        loading: false,
      });
    }
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    this.onSaveSort(property, isAsc ? 'desc' : 'asc');
  };

  onSaveSort = (value, order) => {
    const { currentUser } = this.context;
    const { props } = currentUser;

    let array = { ...props };
    if (props && props.sorting) {
      let { sorting } = props;
      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.Roles = [value, order];
      } else {
        obj.Roles = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        Roles: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;
    const { username, firstName, lastName, email, password, phone, id } =
      currentUser;

    const payload = {
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      props: properties,
    };

    const url = ApiConstant.PUT_USER(id);

    const response = await put({
      url: url,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  handleSelectAllClick = (event) => {
    // debugger
    if (event.target.checked) {
      const newSelecteds = this.state.roleList.map((n) => n.id);

      this.setState({ selected: newSelecteds, selectedRoles: newSelecteds });

      return;
    }
    this.setState({ selected: [], selectedRoles: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    this.roleToDelete(name);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  // filter
  onFilter = (status) => {
    const { roleRefList } = this.state;

    const filterList = roleRefList.filter(
      (data) => data.status.toLowerCase() === status.toLowerCase()
    );

    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  // search
  onSearchData = (value) => {
    const { roleRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = roleRefList.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ roleList: result, page: 0 });
    } else {
      this.setState({ roleList: roleRefList });
    }
  };

  onFilterData = (value) => {
    let resArray = [];
    const { roleRefList } = this.state;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ roleList: resArray, page: 0 });
    } else {
      this.setState({ roleList: roleRefList });
    }

    // const filter = clusterList.filter((data)=>data.status  )
  };
  // DElete

  roleToDelete = (id) => {
    const { selectedRoles } = this.state;
    let selectedRole = [...selectedRoles];
    const isPresent = selectedRoles.includes(id);
    const index = selectedRoles.indexOf(id);

    if (!isPresent) {
      selectedRole.push(id);
      this.setState({ selectedRoles: selectedRole });
    } else {
      selectedRole.splice(index, 1);
      this.setState({ selectedRoles: selectedRole });
    }
  };

  onDeleteRole = async () => {
    const { selectedRoles } = this.state;
    let success = [];
    let failure = [];
    for (let z = 0; z < selectedRoles.length; z++) {
      const response = await deleteRoleById(selectedRoles[z]);

      if (response === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
    }
    if (success.length > 0) {
      deleteMessage(200, `${success.length} Roles deleted`);
    }
    if (failure.length > 0) {
      deleteMessage(200, `${failure.length} Roles failed to delete`, true);
    }
    this.onDeleteDone();
  };
  onDeleteDone = () => {
    this.setState(
      {
        roleList: [],
        selectedRoles: [],
        selected: [],
        page: 0,
        isDelete: false,
        loading: true,
      },
      () => {
        this.getRoles();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      roleList,
      loading,
      isDone,
      selectedRoles,
      isDelete,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="d-flex justify-content-end gap-3 mb-4">
          <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(`${CREATE_ROLES_PATH}/create`)
            }
          >
            <i className="bi bi-plus-lg " />
            Create Role
          </button>
          <button
            className="buttonX white"
            onClick={this.handleDeleteModal}
            disabled={selectedRoles.length === 0}
          >
            <i className="bi bi-trash3" />
            Delete Roles
          </button>
        </div>
        <CommonCard className="">
          <div className="row">
            <div className="col-md-8">
              <div className="text-left mb-3">
                <input
                  type="search"
                  placeholder="Search By Name"
                  className="input-boxes"
                  onChange={(e) => this.onSearchData(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-right ">
                <MultiSelect
                  closeMenuOnSelect={false}
                  isMulti
                  options={options}
                  handleChange={(value) => this.onFilterData(value)}
                  placeholder="Filter By Status"
                />
              </div>
            </div>
          </div>

          <>
            <div className="desktop-table">
              <TableContainer>
                <Table
                  className="tableX"
                  sx={{
                    minWidth: 750,
                  }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={roleList.length}
                    headCells={headCells}
                  />
                  {isDone && roleList && roleList.length > 0 ? (
                    <TableBody className="table Table">
                      {stableSort(roleList, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((roleData, index) => {
                          const isItemSelected = this.isSelected(roleData.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {!roleData.is_system_role && (
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    onClick={(event) =>
                                      this.handleClick(event, roleData.id)
                                    }
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                onClick={() =>
                                  !roleData.is_system_role
                                    ? this.props.history.push(
                                        `${ROLE_DETAILS_PATH}/${roleData.id}`,
                                        roleData
                                      )
                                    : null
                                }
                                align="left"
                              >
                                <span
                                  data-testid={`role-id ${index}`}
                                  className={
                                    !roleData.is_system_role ? 'id' : ''
                                  }
                                >
                                  {roleData.name}
                                </span>
                              </TableCell>

                              <TableCell align="left">
                                {roleData.description
                                  ? roleData.description
                                  : '-'}
                              </TableCell>
                              {/* <TableCell align="left">
                                <span
                                  className={
                                    roleData.status === "inactive" ||
                                    roleData.status === "Inactive"
                                      ? "disable"
                                      : "available"
                                  }
                                >
                                  {roleData.status}
                                </span>
                              </TableCell> */}
                              <TableCell align="left">
                                {!roleData.is_system_role && (
                                  <RoleMenu
                                    onComplete={this.onDeleteDone}
                                    title={roleData.name}
                                    id={roleData.id}
                                    history={this.props.history}
                                    data={roleData}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
              {loading ? (
                <SkeletonLoader />
              ) : isDone && roleList && roleList.length > 0 ? (
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  component="div"
                  count={roleList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              ) : !loading && roleList && roleList.length === 0 ? (
                <p className="text_color text-center no-data-msg mt-2">
                  No Data Found
                </p>
              ) : null}
            </div>
            <div className="mobile-table">
              {loading ? (
                <SkeletonLoader />
              ) : isDone && roleList ? (
                roleList.map((roleData, index) => {
                  return (
                    <div key={index} className="mob-main">
                      <div className="mob-rows">
                        <div className="keys">Name</div>
                        <div
                          onClick={() =>
                            !roleData.is_system_role
                              ? this.props.history.push(
                                  `${ROLE_DETAILS_PATH}/${roleData.id}`,
                                  roleData
                                )
                              : null
                          }
                          className={
                            !roleData.is_system_role ? 'values id' : 'values'
                          }
                        >
                          {roleData.name}
                        </div>
                      </div>

                      <div className="mob-rows">
                        <div className="keys">Description</div>
                        <div className="values">
                          {roleData.description ? roleData.description : '-'}
                        </div>
                      </div>
                      {/* <div className="mob-rows">
                        <div className="keys">Status</div>
                        <div
                          className={
                            roleData.status === "Inactive"
                              ? "disable values"
                              : "available values"
                          }
                        >
                          {roleData.status}
                        </div>
                      </div> */}
                      <div className="mob-rows">
                        <div className="keys">Actions</div>
                        <div className="text_color_main">
                          {!roleData.is_system_role && (
                            <RoleMenu
                              onComplete={this.onDeleteDone}
                              title={roleData.name}
                              id={roleData.id}
                              history={this.props.history}
                              data={roleData}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : !loading && roleList && roleList.length === 0 ? (
                <p className="text_color text-center no-data-msg mt-2">
                  No Data Found
                </p>
              ) : null}
            </div>
          </>
        </CommonCard>

        {isDelete && (
          <DeleteModal
            deleteCount={selectedRoles.length}
            title={selectedRoles?.length > 1 ? 'Roles' : 'Role'}
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteRole}
          />
        )}
      </Layout>
    );
  }
}
